<h2 mat-dialog-title>Envelope Orders</h2>
<mat-dialog-content>
    <div class="table-container">
        <table mat-table [dataSource]="envelopeOrders$" multiTemplateDataRows
            *ngIf="((envelopeOrders$ | async)?.length) ?? 0 > 0">

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef id="reference">Order Reference</th>
                <td mat-cell *matCellDef="let eo"> {{ eo.orderReferenceNumber }} </td>
            </ng-container>

            <ng-container matColumnDef="added">
                <th mat-header-cell *matHeaderCellDef id="added">Added Date</th>
                <td mat-cell *matCellDef="let eo"> {{ eo.added | localizedDate }} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef id="type">Order Type</th>
                <td mat-cell *matCellDef="let eo"> {{ eo.orderType }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef id="status">Order Status</th>
                <td mat-cell *matCellDef="let eo"> {{ eo.orderStatus }} </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef id="view"> </th>
                <td mat-cell *matCellDef="let eo">
                    <button mat-icon-button color="accent" (click)="viewOrder(eo)" aria-label="View Order">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef id="delete"> </th>
                <td mat-cell *matCellDef="let eo">
                    <button mat-icon-button color="primary" [disabled]="disableDeletion()" (click)="onDelete(eo)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <ng-template #none>
        <span class="text-faded">No orders found.</span>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions class="flex-container-right-align">
    <div class="spacer"></div>
    <button class="flex-item-fixed-size" mat-raised-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>