import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Order } from '../../interfaces/orders/order';
import { User } from '../../interfaces/users/user';
import { ExternalOrderNumber } from '../../interfaces/external-order-number/external-order-number';
import { OrderHistoryComponent } from '../order-history/order-history.component';
import { Subject, takeUntil } from 'rxjs';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { OrderMetaData } from '../../interfaces/order-meta-data';

@Component({
  selector: 'lib-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  order!: Order
  @Input() orderMetaData!: OrderMetaData;
  @Input() user!: User | null;
  @Input() isLockedByMe: boolean = false;
  @Input() shouldShowUserDetails: boolean = true;
  @Input() shouldShowExternalOrderDetails: boolean = true;
  @Output() saveExternalOrderNumberEvent = new EventEmitter<ExternalOrderNumber>();

  externalOrderNumber: ExternalOrderNumber | null | undefined;

  private unsubscribe$ = new Subject<void>();

  constructor(private dialog: MatDialog,
    private orderManager: OrderManagerService
  ) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order => {
        this.order = order

        this.externalOrderNumber = this.order.searchResult?.externalOrderNumber ?? this.order.discharge?.externalOrderNumber ?? this.order.lien?.registrationResults?.[0]?.externalOrderNumber;
      });
  }

  handleSaveExternalOrderNumberEvent(savedExternalOrderNumber: ExternalOrderNumber) {
    this.saveExternalOrderNumberEvent.emit(savedExternalOrderNumber);
  }

  openOrderHistory() {
    this.dialog.open(OrderHistoryComponent, { data: this.order });
  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
