import { Component, Input } from '@angular/core';
import { RegistrationResults } from '../../interfaces/registration-results/registration-results';

@Component({
  selector: 'lib-amendment-results',
  templateUrl: './amendment-results.component.html',
  styleUrls: ['./amendment-results.component.css']
})
export class AmendmentResultsComponent {
  @Input() result: RegistrationResults | undefined;
}
