import { Component } from '@angular/core';
import { ScheduledMonitorsRepositoryService } from '../../../services/scheduled-monitors/scheduled-monitors-repository.service';
import { AutomationMonitorComponent } from '../base-monitor-component';
import { AutomationMonitor, ConfirmDialogComponent } from 'reg-hub-common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-scheduled-monitors',
  templateUrl: './scheduled-monitors.component.html',
  styleUrls: ['./scheduled-monitors.component.css']
})
export class ScheduledMonitorsComponent extends AutomationMonitorComponent {

  protected displayedColumns = [
    'icon',
    'automationName',
    'scheduledFrequency',
    'warningFrequency',
    'lastSuccessfulRunDate',
    'delete'
  ]

  constructor(private scheduledMonitorService: ScheduledMonitorsRepositoryService,
    private dialog: MatDialog) { 
    super(scheduledMonitorService);
  }

  onDeleteMonitor(automationMonitor: AutomationMonitor) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { 
        title: "Are you sure you want to delete this automation monitor?", 
        confirmationPrompt: "",
        positiveResponseLabel: "Delete",
        negativeResponseLabel: "Cancel"
      },
      minWidth: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // submission is confirmed, submit order
        this.deleteAutomationMonitor(automationMonitor);
      }
    });
  }

  deleteAutomationMonitor(automationMonitor: AutomationMonitor) {
    this.scheduledMonitorService.deleteMonitor(automationMonitor.id)
    .subscribe({
      next: () => {
        this.repo.refresh();
      },
      error: () => {
        //Handle Error
      }
    });
  }
}
