<!-- Dialog Title -->
<h2 *ngIf="!clientAdded" mat-dialog-title>Add New Client</h2>

<!-- Dialog Content -->
<mat-dialog-content>
    <div class="dialog-content">
        <div *ngIf="!clientAdded; else clientAddedTemplate">
            <!-- Initial Form State -->
            <form [formGroup]="clientForm">
                <mat-form-field appearance="outline" class="form-field client-form">
                    <mat-label>Client Name</mat-label>
                    <input matInput formControlName="clientID" required>
                </mat-form-field>
                <!-- Add other fields as necessary -->
            </form>
        </div>
    </div>
</mat-dialog-content>

<!-- Dialog Actions -->
<mat-dialog-actions align="center" *ngIf="!clientAdded">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="clientForm.invalid">Add</button>
</mat-dialog-actions>

<!-- Success Template -->
<ng-template #clientAddedTemplate>
    <!-- Success Message State -->
    <div class="success-message">
        <p><strong>{{ clientName }}</strong> has been added successfully!</p>

        <mat-divider></mat-divider>

        <p><strong>Client Secret:</strong></p>
        <div class="client-secret-container">
            <p class="client-secret">{{ clientSecret }}</p>
            <button mat-icon-button (click)="copyClientSecret()" matTooltip="Copy to Clipboard">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>

        <p class="warning-message">
            Save this secret! After this dialog is closed, you will not be shown it again.
        </p>

        <div class="actions">
            <button mat-raised-button color="accent" (click)="closeDialog()">Close</button>
        </div>
    </div>
</ng-template>