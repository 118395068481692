import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { RegistrationDefault } from '../../interfaces/registration-defaults/registration-default';
import { RegistrationDefaultForCreation } from '../../interfaces/registration-defaults/registration-default-for-creation';
import { RegistrationDefaultForUpdate } from '../../interfaces/registration-defaults/registration-default-for-update';

@Injectable({
  providedIn: 'root'
})
export class RegistrationDefaultsRepositoryService extends PaginatedRepository<RegistrationDefault> {
  protected override _baseLink: string;

  constructor(
      http: HttpClient,
      messageService: ToastrService,
      @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      
      super(http,messageService);

      this._currentParams = new HttpParams()
          .set('pageSize', 10);

      this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/RegistrationDefaults`;
      this._baseLink = this._baseLinkTemplate;
  }

  private generateHeaders = () => {
      return {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
  }

  public createRegistrationDefault = (corporationID: string, registrationDefault: RegistrationDefaultForCreation, userGroupID?: string | null) => {
    var urlSlug = `/Corporations/${corporationID}`
    if (userGroupID) {
      urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
    }

    return this.http.post<RegistrationDefault>(
        `${this.config.rootUrl}${urlSlug}/RegistrationDefaults`,
        registrationDefault,
        this.generateHeaders());
  }

  public getRegistrationDefault = (corporationID: string, registrationDefaultID: string, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.get<RegistrationDefault>(
          `${this.config.rootUrl}${urlSlug}/RegistrationDefaults/${registrationDefaultID}`,
          this.generateHeaders());
  }

  public updateRegistrationDefault = (corporationID: string, registrationDefaultID: string, registrationDefault: RegistrationDefaultForUpdate, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.put<RegistrationDefaultForUpdate>(
          `${this.config.rootUrl}${urlSlug}/RegistrationDefaults/${registrationDefaultID}`,
          registrationDefault,
          this.generateHeaders());
  }

  public deleteRegistrationDefault = (corporationID: string, registrationDefaultID: string, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.delete(
          `${this.config.rootUrl}${urlSlug}/RegistrationDefaults/${registrationDefaultID}`,
          this.generateHeaders());
  }
}
