import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order, OrderRepositoryService } from 'reg-hub-common';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  stagger,
} from '@angular/animations';

@Component({
  selector: 'app-failed-orders',
  templateUrl: './failed-orders.component.html',
  animations: [
    trigger('tableAnimation', [
      transition(':enter', [
        // Initial state: the row is not visible
        style({ height: '0px', opacity: 0 }),
        // Animate to the final state: full height and fully visible
        animate('200ms ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        // Animate the row disappearing
        animate('200ms ease-in', style({ height: '0px', opacity: 0 })),
      ]),
    ]),
  ]
})
export class FailedOrdersComponent implements OnInit {

  @Input() data: Order[] = [];
  @Input() total: number = 0;
  @Input() loading: boolean = false;

  protected displayedColumns = [
    'referenceNumber',
    'status',
    'orderTypeID',
    'jurisdiction'
  ]

  constructor(
    protected repo: OrderRepositoryService,
    protected router: Router) { }

  ngOnInit(): void { }

  protected onViewAll() {
    let params = {
      status: ['FailedAutomation','Exception','RegistryOutage','ErrorCorrection'],
      isVisible: 'true',
      sortColumn: 'added',
      sortDirection: 'desc'
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }
}
