<div *ngIf="this.repo.resource$ | async as data">
    <!-- Scheduled monitoring table -->
    <table mat-table matSort (matSortChange)="sort($event)" [dataSource]="data" multiTemplateDataRows *ngIf="data.length > 0">

        <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let monitor"> 
                <mat-icon [class]="getIconClass(monitor)">{{ getIcon(monitor) }}</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="automationName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef id="automationName"> Process Name </th>
            <td mat-cell *matCellDef="let monitor"> {{ monitor.automationName }} </td>
        </ng-container>

        <ng-container matColumnDef="scheduledFrequency">
            <th mat-header-cell *matHeaderCellDef id="scheduledFrequency"> Run Frequency </th>
            <td mat-cell *matCellDef="let monitor"> Every {{ monitor.scheduledFrequency }} hours </td>
        </ng-container>

        <ng-container matColumnDef="warningFrequency">
            <th mat-header-cell *matHeaderCellDef id="warningFrequency"> Warning Frequency </th>
            <td mat-cell *matCellDef="let monitor"> Every {{ monitor.warningFrequency }} hours </td>
        </ng-container>

        <ng-container matColumnDef="lastSuccessfulRunDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef id="lastSuccessfulRunDate"> Last Successful Run </th>
            <td mat-cell *matCellDef="let monitor"> {{ monitor.lastSuccessfulRunDate | localizedDate: 'yyyy-MM-dd hh:mm' }} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let monitor">
                <button mat-icon-button color="accent" (click)="onDeleteMonitor(monitor)" aria-label="Delete Monitor">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!-- End of scheduled monitoring table -->
    
    <div class="spacer-small"></div>

    <mat-paginator *ngIf="data.length > 0" [pageSize]="this.repo.pageSize$ | async"
        [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
        [pageSizeOptions]="[5, 10, 25]" [showFirstLastButtons]="((this.repo.totalPages$ | async) ?? 1) > 2"
        (page)="page($event)"></mat-paginator>
</div>