<form [formGroup]="_filterForm">
    <div class="flex-container">
        <mat-form-field class="form-field flex-item">
            <mat-label>Client ID</mat-label>
            <input matInput [formControlName]="'clientID'" placeholder="Client ID" />
        </mat-form-field>
    </div>

    <div class="flex-container">
        <mat-checkbox formControlName="isActive" class="form-field">
            Active
        </mat-checkbox>
    </div>

    <div class="spacer-small"></div>

    <div>
        <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Clear filters button"
            (click)="onClear()" [disabled]="!_filterForm.dirty">
            Clear Filters
        </button>
        <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Filter button" type="submit"
            (click)="onFilter()">
            Filter
        </button>
    </div>
</form>