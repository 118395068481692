import { Component, OnInit } from '@angular/core';
import { Client } from 'reg-hub-common';
import { MatDialog } from '@angular/material/dialog';
import { ClientFormComponent } from '../client-form/client-form.component';
import { ClientRepositoryService } from '../../../services/clients/client-repository.service';
import { ListComponent } from 'reg-hub-common';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent extends ListComponent<Client> {

  clients: Client[] = [];
  columnsToDisplay: string[] = ['clientID', 'isActive'];

  protected override repo: ClientRepositoryService;

  constructor(
    repo: ClientRepositoryService,
    private dialog: MatDialog
  ) {
    super(repo);
    this.repo = repo;
  }

  toggleClientStatus(client: Client) {
    const updatedClient = { ...client, isActive: !client.isActive };
    this.repo.updateClient(client.id, updatedClient).subscribe(() => {
      client.isActive = !client.isActive;
    });
  }

  openAddClientDialog() {
    const dialogRef = this.dialog.open(ClientFormComponent, {
      width: '600px',
      height: '350px',
      maxWidth: '90vw',
      maxHeight: '90vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.repo.getFirstPage();
      }
    });
  }
}