import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InternalSearchCriteria } from '../../interfaces/internal-search-criteria';

@Component({
  selector: 'lib-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
  searchOptions = [
    { value: 'businessName', label: 'Business Name' },
    { value: 'dateOfBirth', label: 'Date of Birth' },
    { value: 'firstName', label: 'First Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'orderId', label: 'OrderID' },
    { value: 'referenceNumber', label: 'Reference Number' },
    { value: 'registrationNumber', label: 'Registration Number' },
    { value: 'serialNumber', label: 'Serial Number' }
  ];

  protected searchCriteria: string = '';
  protected typeOfSearch: string = 'referenceNumber';

  @Input() shouldShowExternalOrderNumber = false;
  @Output() search = new EventEmitter<InternalSearchCriteria>();

  constructor() { }

  ngOnInit(): void {
    if (this.shouldShowExternalOrderNumber) {
      const newItem = { value: 'externalOrderNumber', label: 'External Order Number' };
      const index = this.searchOptions.findIndex(option => option.value == 'dateOfBirth');
  
      if (index !== -1) {
        this.searchOptions.splice(index + 1, 0, newItem);
      }
    }
  }

  protected performSearch() {
    this.search.emit({
      typeOfSearch: this.typeOfSearch,
      criteria: this.searchCriteria.trim(),
    });

    this.searchCriteria = ''
  }
}