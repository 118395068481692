import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsRepositoryService, eEnvelopeStatusType, Envelope, EnvelopeRepositoryService, ListComponent } from 'reg-hub-common';
import { interval, Subject, switchMap, takeUntil, takeWhile, timer } from 'rxjs';
import { EnvelopeOrdersPopupComponent } from '../envelope-orders-popup/envelope-orders-popup.component';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-envelopes',
  templateUrl: './envelopes.component.html',
  styleUrls: ['./envelopes.component.css']
})
export class EnvelopesComponent extends ListComponent<Envelope> implements OnInit, OnDestroy {
  displayedColumns = [
    "envelopeId",
    "added",
    "numOrders",
    "icon",
    "status",
    "document",
    "actions"
  ]

  private onDestroy$ = new Subject<void>();
  protected pageSize = 4;
  protected includeCompleted = false;

  processing = new Map<string, boolean>();

  constructor(
    private dialog: MatDialog,
    protected envelopesRepository: EnvelopeRepositoryService,
    private documentsRepository: DocumentsRepositoryService,
    private router: Router) {
    super(envelopesRepository);
  }

  ngOnInit(): void {
    let params = new HttpParams()
      .set('pageSize', this.pageSize);

    this.repo.getFirstPage(this.setParamsWithoutComplete(params));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setParamsWithoutComplete(params: HttpParams): HttpParams {
    return params
      .append('envelopeStatus', eEnvelopeStatusType.open)
      .append('envelopeStatus', eEnvelopeStatusType.sealed)
      .append('envelopeStatus', eEnvelopeStatusType.processingError)
      .append('envelopeStatus', eEnvelopeStatusType.validationError)
      .append('envelopeStatus', eEnvelopeStatusType.importing)
      .append('envelopeStatus', eEnvelopeStatusType.importError)
      .append('envelopeStatus', eEnvelopeStatusType.imported)
      .append('envelopeStatus', eEnvelopeStatusType.submitting)
      .append('envelopeStatus', eEnvelopeStatusType.submissionError)
      .append('envelopeStatus', eEnvelopeStatusType.submitted)
      .append('envelopeStatus', eEnvelopeStatusType.resultsRetrieval)
      .append('envelopeStatus', eEnvelopeStatusType.resultsRetrievalError);
  }

  setParamsWithComplete(params: HttpParams): HttpParams {
    return this.setParamsWithoutComplete(params)
      .append('envelopeStatus', eEnvelopeStatusType.complete);
  }

  onCompletedFilterChange() {
    if (this.includeCompleted) {
      this.repo.filter(this.setParamsWithComplete(new HttpParams()));
    } else {
      this.repo.filter(this.setParamsWithoutComplete(new HttpParams()));
    }
  }

  viewLogs(envelope: Envelope) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['logs'], { queryParams: { tab: 'changes', entityID: envelope.id, entityName: 'Envelope' } })
    );

    window.open(url, '_blank');
  }

  viewOrders(envelope: Envelope) {
    this.dialog.open(EnvelopeOrdersPopupComponent, {
      data: envelope,
      minWidth: '800px'
    })
  }

  downloadDocument(envelope: Envelope) {
    if (!envelope.document) {
      return;
    }

    this.documentsRepository.viewAndDownloadDocumentWithType(envelope.document.id, "application/xml");
  }

  openEnvelope(envelope: Envelope) {
    this.envelopesRepository.updateEnvelopeStatus(envelope.id, eEnvelopeStatusType.open)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.envelopesRepository.refresh());
  }

  sealEnvelope(envelope: Envelope) {
    this.envelopesRepository.updateEnvelopeStatus(envelope.id, eEnvelopeStatusType.sealed)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.envelopesRepository.refresh());
  }

  completeEnvelope(envelope: Envelope) {
    this.envelopesRepository.updateEnvelopeStatus(envelope.id, eEnvelopeStatusType.complete)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.envelopesRepository.refresh());
  }

  retrieveResults(envelope: Envelope) {
    this.processEnvelope(envelope, eEnvelopeStatusType.resultsRetrieval);
  }

  processEnvelope(envelope: Envelope, newStatus: eEnvelopeStatusType) {
    this.processing.set(envelope.id, true);
    this.envelopesRepository.updateEnvelopeStatus(envelope.id, newStatus, 'Updating envelope status from admin client.').subscribe();

    let refresh$ = interval(5000)
      .pipe(
        takeUntil(this.onDestroy$),
        takeUntil(timer(90000)),
        switchMap(() => this.envelopesRepository.refreshSubscribable()),
        takeWhile(envelopes => envelopes.find(env => env.id === envelope.id)?.statusLastUpdated === envelope.statusLastUpdated))
      .subscribe({ complete: () => this.processing.set(envelope.id, false) });
  }

  isProcessing(envelope: Envelope) {
    return this.processing.get(envelope.id);
  }

  importEnvelope(envelope: Envelope) {
    this.processEnvelope(envelope, eEnvelopeStatusType.importing);
  }

  submitEnvelope(envelope: Envelope) {
    this.processEnvelope(envelope, eEnvelopeStatusType.submitting);
  }

  shouldShowOpenAction(envelope: Envelope) {
    return envelope.validEnvelopeStateTransitions?.some(transition => transition.endingState === eEnvelopeStatusType.open);
  }

  shouldShowSealAction(envelope: Envelope) {
    return envelope.validEnvelopeStateTransitions?.some(transition => transition.endingState === eEnvelopeStatusType.sealed);
  }

  shouldShowImportAction(envelope: Envelope) {
    return envelope.validEnvelopeStateTransitions?.some(transition => transition.endingState === eEnvelopeStatusType.importing);
  }

  shouldShowSubmitAction(envelope: Envelope) {
    return envelope.validEnvelopeStateTransitions?.some(transition => transition.endingState === eEnvelopeStatusType.submitting);
  }

  shouldShowRetrieveResultsAction(envelope: Envelope) {
    return envelope.validEnvelopeStateTransitions?.some(transition => transition.endingState === eEnvelopeStatusType.resultsRetrieval);
  }

  shouldShowCompleteAction(envelope: Envelope) {
    return envelope.validEnvelopeStateTransitions?.some(transition => transition.endingState === eEnvelopeStatusType.complete);
  }

  getIcon(envelope: Envelope) {
    switch (envelope.envelopeStatus) {
      case eEnvelopeStatusType.open:
        return 'folder_open';
      case eEnvelopeStatusType.sealed:
        return 'folder';
      case eEnvelopeStatusType.importError:
      case eEnvelopeStatusType.processingError:
      case eEnvelopeStatusType.validationError:
      case eEnvelopeStatusType.submissionError:
      case eEnvelopeStatusType.resultsRetrievalError:
        return 'error_outline';
      case eEnvelopeStatusType.importing:
      case eEnvelopeStatusType.imported:
        return 'history';
      case eEnvelopeStatusType.submitting:
      case eEnvelopeStatusType.submitted:
        return 'archive';
      case eEnvelopeStatusType.resultsRetrieval:
        return 'file_copy';
      case eEnvelopeStatusType.complete:
        return 'check_circle_outline';
      default:
        return '';
    }
  }
}
