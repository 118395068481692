import { Component, Input, OnInit } from '@angular/core';
import { Client, FiltersComponent } from 'reg-hub-common';
import { ClientRepositoryService } from '../../../services/clients/client-repository.service';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-client-filter',
  templateUrl: './client-filter.component.html',
  styleUrls: ['./client-filter.component.css']
})
export class ClientFilterComponent extends FiltersComponent<Client> implements OnInit {

  constructor(
    repo: ClientRepositoryService,
    formBuilder: FormBuilder,
    datePipe: DatePipe
  ) {
    super(repo, formBuilder, datePipe);
  }

  ngOnInit(): void {
    this._filterForm = this.formBuilder.group({
      clientID: [null],
      isActive: [null]
    });

    this.repo.getFirstPage(this._defaultParams);
  }
}
