<div class="padding">
    <!-- Main container -->
    <div *ngIf="!this._loading">
        <div class="flex-container">
            <!-- Pending orders widget -->
            <div class="flex-item">
                <app-manual-orders [data]="this._manualOrders" [total]="this._totalManualOrders" [loading]="this._loading"></app-manual-orders>
            </div>
            <!-- End of pending orders widget-->

            <!-- Failed orders widget -->
            <div class="flex-item">
                <app-failed-orders [data]="this._failedOrders" [total]="this._totalFailedOrders" [loading]="this._loading"></app-failed-orders>
            </div>
            <!-- End of failed orders widget -->
        </div>
        <div class="flex-container">
            <!-- Database overviews widget -->
            <div class="flex-item">
                <app-database-overview [dataSource]="this._databaseOverviews" [loading]="this._loading"></app-database-overview>
            </div>
            <!-- End of database overviews Widget -->
        </div>
        <div class="flex-container">
            <!-- Envelopes overview widget -->
            <div class="flex-item">
                <app-envelopes></app-envelopes>
            </div>
            <!-- Envelopes overview Widget -->
        </div>
        <div class="flex-container">
            <!-- Envelopes overview widget -->
            <div class="flex-item">
                <app-batch-summaries></app-batch-summaries>
            </div>
            <!-- Envelopes overview Widget -->
        </div>
        <div class="flex-container">
            <!-- Automation monitors widget -->
            <div class="flex-item">
                <app-automation-monitors [loading]="this._loading"></app-automation-monitors>
            </div>
            <!-- End of automation monitors widget -->
        </div>
    </div>
    <!-- End of main container -->

    <!-- Spinner (replaces everything and waits until all requests have completed to show anything) -->
    <div *ngIf="this._loading" class="loading-spinner-overlay">
        <mat-spinner></mat-spinner>
    </div>
    <!-- End of spinner -->
</div>