<h4>Requesting User Details</h4>

<div class="flex-container">
    <span class="flex-item">User:</span>
    <a
        *ngIf="user?.id"
        class="flex-item-2 link-style"
        [routerLink]="['/corporations', order.orderGroup?.corporationID, 'usergroups', user?.userGroupID, 'users', user?.id]"
        aria-label="User Group Link">
        {{ user?.userName ?? "" }} ({{ userType }})
    </a>
</div>
<div class="flex-container">
    <span class="flex-item">Email:</span><span class="flex-item-2">{{ user?.contactDetails?.email ?? "" }}</span>
</div>
<div class="flex-container">
    <span class="flex-item">User Group:</span>
    <a
        *ngIf="user?.userGroupID"
        class="flex-item-2 link-style"
        [routerLink]="['/corporations', order.orderGroup?.corporationID, 'usergroups', user?.userGroupID]"
        aria-label="User Group Link">
        {{ orderMetaData.requestingUserGroupName ?? '' }}
    </a>
</div>
<div class="flex-container">
    <span class="flex-item">Corporation:</span>
    <a
        *ngIf="order.orderGroup?.corporationID"
        class="flex-item-2 link-style"
        [routerLink]="['/corporations', order.orderGroup?.corporationID]"
        aria-label="Corporation Link">
        {{ orderMetaData.requestingCorporationName ?? '' }}
    </a>
</div>