<div class="flex-container" *ngIf="order.jurisdiction == 'ON'">
  <span class="flex-item">File Number:</span>
  <span class="flex-item-2">{{ order.lien?.fileNumber }}</span>
</div>
<div class="flex-container">
  <span class="flex-item">Discharge Number:</span>
  <span class="flex-item-2">{{ discharge?.dischargeNumber }}</span>
</div>
<div class="flex-container">
  <span class="flex-item">Discharge Date:</span>
  <span class="flex-item-2">{{ discharge?.dischargeDate | localizedDate: "yyyy-MM-dd" }}</span>
</div>
<div class="flex-container" *ngIf="discharge?.dischargeType">
  <span class="flex-item">Discharge Type:</span>
  <span class="flex-item-2">{{ discharge?.dischargeType }}</span>
</div>