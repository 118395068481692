import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AutomationMonitor, PaginatedRepository } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduledMonitorsRepositoryService extends PaginatedRepository<AutomationMonitor> {
  protected override _baseLink: string;

  constructor(
    private environmentUrlService: EnvironmentUrlService,
    http: HttpClient,
    messageService: ToastrService
  ) {
    super(
      http,
      messageService)

    this._currentParams = new HttpParams()
      .set('pageSize', 5)
      .set('automationType', 'scheduled');

    this._baseLink = `${this.environmentUrlService.urlAddress}/AutomationMonitors`;
  }

  public deleteMonitor = (monitorID: string) => {
    return this.http.delete(`${this._baseLink}/${monitorID}`);
  }
}
