<div class="padding">
    <div class="flex-container-space-between">
        <h1 class="left-box">Clients</h1>
        <button (click)="openAddClientDialog()" mat-fab matTooltip="Add"
            aria-label="Button that navigates to add new corporation page." color="accent">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div *ngIf="this.repo.resource$ | async as data">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="bold">
                    Filters
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Optional Filter -->
            <app-client-filter></app-client-filter>
        </mat-expansion-panel>

        <div class="spacer-small"></div>

        <!-- Clients Table -->
        <div class="table-container">

            <!-- Conditional paginator at the top (show only when number of orders displayed is >= 50) -->
            <div *ngIf="data.length >= 50">
                <mat-paginator [pageSize]="this.repo.pageSize$ | async"
                    [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                    [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true"
                    (page)="page($event)"></mat-paginator>
                <div class="spacer-small"></div>
            </div>
            <!-- End of conditional paginator at the top -->

            <table mat-table matSort [dataSource]="data" multiTemplateDataRows
                (matSortChange)="sort($event)" *ngIf="data.length > 0">
                <!-- Name Column -->
                <ng-container matColumnDef="clientID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client ID </th>
                    <td mat-cell *matCellDef="let client"> {{ client.clientID }} </td>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="isActive">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                    <td mat-cell *matCellDef="let client">
                        <mat-slide-toggle [checked]="client.isActive" (change)="toggleClientStatus(client)">
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <!-- Header and Row Declarations -->
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
            </table>

            <mat-paginator *ngIf="data.length > 0" [pageSize]="this.repo.pageSize$ | async"
                [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true"
                (page)="page($event)"></mat-paginator>
            <!-- End of paginator for the bottom -->
        </div>

        <div *ngIf="this.repo.loading$ | async" class="loading-spinner-overlay">
            <mat-spinner></mat-spinner>
        </div>
        <!-- End of spinner -->

        <lib-placeholder [iconName]="'cancel'" [iconClass]="'icon-error'" message="Error loading registry credentials"
            [condition]="(this.repo.error$ | async) ?? false"></lib-placeholder>
    </div>
</div>