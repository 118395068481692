import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Batch } from '../../interfaces/batches/batch';
import { BatchStateForUpdate } from '../../interfaces/batch-state/batch-state-for-update';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { eBatchState } from '../../enums';
import { ValidBatchStateTransitions } from '../../interfaces/batch-state/valid-batch-state-transitions';

@Injectable({
    providedIn: 'root'
})
export class BatchStateRepositoryService {
    private _baseLink: string;
    private _rootURL: string;

    constructor(
        private http: HttpClient,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        this._baseLink = `${config.batchRootURL}/Collections`;
        this._rootURL = `${config.batchRootURL}`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getValidBatchStateTransitions(batch: Batch) {
        return this.getValidBatchStateTransitionsByState(batch.batchStateID);
    }

    public getValidBatchStateTransitionsByState(batchState: eBatchState) {
        return this.http.get<ValidBatchStateTransitions>(`${this._rootURL}/BatchStates/${batchState}/Transitions`);
    }

    public updateBatchState = (collectionID: string, batchID: string, batchStatus: BatchStateForUpdate) => {
        return this.http.put(`${this._baseLink}/${collectionID}/Batches/${batchID}/State`, batchStatus, this.generateHeaders());
    }
}
