import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { OrderFee } from '../../interfaces/order-fees/order-fee';

@Injectable({
  providedIn: 'root'
})
export class InvoiceOrderFeeRepositoryService extends PaginatedRepository<OrderFee> {
  protected override _baseLink: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
    super(
      http,
      messageService);

    this._baseLinkTemplate = `${config.rootUrl}/Invoices/[0]/OrderFees`;
    this._baseLink = `${config.rootUrl}/Invoices`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
}
