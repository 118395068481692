import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { ReusableClassification } from '../../interfaces/reusable-classifications/reusable-classification';
import { ReusableClassificationForCreation } from '../../interfaces/reusable-classifications/reusable-classification-for-creation';
import { ReusableClassificationForUpdate } from '../../interfaces/reusable-classifications/reusable-classification-for-update';

@Injectable({
  providedIn: 'root'
})
export class ReusableClassificationsRepositoryService extends PaginatedRepository<ReusableClassification> {
  protected override _baseLink: string;

  constructor(
      http: HttpClient,
      messageService: ToastrService,
      @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      
      super(http,messageService);

      this._currentParams = new HttpParams()
          .set('pageSize', 10);

      this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/ReusableClassifications`;
      this._baseLink = this._baseLinkTemplate;
  }

  private generateHeaders = () => {
      return {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
  }

  public createReusableClassification = (corporationID: string, reusableClassification: ReusableClassificationForCreation, userGroupID?: string | null) => {
    var urlSlug = `/Corporations/${corporationID}`
    if (userGroupID) {
      urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
    }

    return this.http.post<ReusableClassification>(
        `${this.config.rootUrl}${urlSlug}/ReusableClassifications`,
        reusableClassification,
        this.generateHeaders());
  }

  public getReusableClassification = (corporationID: string, reusableClassificationID: string, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.get<ReusableClassification>(
          `${this.config.rootUrl}${urlSlug}/ReusableClassifications/${reusableClassificationID}`,
          this.generateHeaders());
  }

  public getReusableClassifications = (corporationID: string, userGroupID?: string | null) => {
    var urlSlug = `/Corporations/${corporationID}`
    if (userGroupID) {
      urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
    }

    return this.http.get<ReusableClassification[]>(
        `${this.config.rootUrl}${urlSlug}/ReusableClassifications?IsVisible=true`,
        this.generateHeaders());
  }

  public updateReusableClassification = (corporationID: string, reusableClassificationID: string, reusableClassification: ReusableClassificationForUpdate, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.put<ReusableClassificationForUpdate>(
          `${this.config.rootUrl}${urlSlug}/ReusableClassifications/${reusableClassificationID}`,
          reusableClassification,
          this.generateHeaders());
  }

  public deleteReusableClassification = (corporationID: string, reusableClassificationID: string, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.delete(
          `${this.config.rootUrl}${urlSlug}/ReusableClassifications/${reusableClassificationID}`,
          this.generateHeaders());
  }
}
