import { Inject, Injectable } from '@angular/core';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { BatchSummary } from '../../interfaces/batches/batch-summary';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
    providedIn: 'root'
})
export class BatchSummaryRepositoryService extends PaginatedRepository<BatchSummary> {
    protected override _baseLink: string;
    private _rootURL: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(http, messageService);

        this._baseLinkTemplate = `${config.batchRootURL}/Collections/[0]/Batches/Summary`;
        this._baseLink = `${config.batchRootURL}/Batches/Summary`;
        this._rootURL = config.batchRootURL;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getBatchSummary = (collectionID: string, batchID: string) => {
        this.populateBaseLink([collectionID]);

        return this.http.get<BatchSummary>(
            `${this._rootURL}/Collections/${collectionID}/Batches/${batchID}/Summary`, 
            this.generateHeaders());
    }
}