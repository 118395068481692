import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Order } from '../../../interfaces/orders/order';
import { PaginatedRepository } from '../../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../../reg-hub-common-config';


@Injectable({
    providedIn: 'root'
})
export class ActivesReportRepositoryService extends PaginatedRepository<Order> {

    protected override _baseLink: string;

    constructor(
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
        http: HttpClient,
        messageService: ToastrService
    ) {
        super(http, messageService);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/Reports/Active`;
        this._baseLink = this._baseLinkTemplate;
    }
}
