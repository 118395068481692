import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QueueMonitorsRepositoryService } from '../../../services/queue-monitors/queue-monitors-repository.service';
import { AutomationMonitorComponent } from '../base-monitor-component';
import { AutomationMonitor, ConfirmDialogComponent } from 'reg-hub-common';

@Component({
  selector: 'app-queue-monitors',
  templateUrl: './queue-monitors.component.html',
  styleUrls: ['./queue-monitors.component.css']
})
export class QueueMonitorsComponent extends AutomationMonitorComponent {
  
  protected displayedColumns = [
    'icon',
    'automationName',
    'isAutomationEnabled',
    'currentQueueSize',
    'maxQueueSize',
    'lastSuccessfulRunDate',
    'delete'
  ]

  constructor(private queueMonitorService: QueueMonitorsRepositoryService,
    private dialog: MatDialog) { 
    super(queueMonitorService);
  }

  onDeleteMonitor(automationMonitor: AutomationMonitor) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { 
        title: "Are you sure you want to delete this automation monitor?", 
        confirmationPrompt: "",
        positiveResponseLabel: "Delete",
        negativeResponseLabel: "Cancel"
      },
      minWidth: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // submission is confirmed, submit order
        this.deleteAutomationMonitor(automationMonitor);
      }
    });
  }

  onUpdateAutomationMonitor(automationMonitor: AutomationMonitor) {
    if (automationMonitor.isAutomationEnabled) {
      //No need for warning when turning back on
      this.updateAutomationMonitor(automationMonitor);
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { 
          title: "Disable Automation", 
          confirmationPrompt: "Are you sure you want to diable this automation monitor?\n\n*NOTE: This will only stop new customer submissions going into the queue. Any existing requests in the queue will continue to be processed.",
          positiveResponseLabel: "Confrm",
          negativeResponseLabel: "Cancel"
        },
        minWidth: '800px'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // submission is confirmed, submit order
          this.updateAutomationMonitor(automationMonitor);
        }
        else {
          automationMonitor.isAutomationEnabled = true;
        }
      });
    }
  }

  updateAutomationMonitor(automationMonitor: AutomationMonitor) {
    this.queueMonitorService.updateMonitor(automationMonitor)
    .subscribe({
      next: () => {
        this.repo.refresh();
      },
      error: () => {
        //Handle Error
      }
    });
  }

  deleteAutomationMonitor(automationMonitor: AutomationMonitor) {
    this.queueMonitorService.deleteMonitor(automationMonitor.id)
    .subscribe({
      next: () => {
        this.repo.refresh();
      },
      error: () => {
        //Handle Error
      }
    });
  }
}
