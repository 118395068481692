import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Asset, AssetForCreation, AssetForUpdate } from "../public-api";
import { AbstractControl } from "@angular/forms";

export class Utilities {
    
    static deepCopy(obj: any): any {
        if (obj === null || typeof obj !== "object") {
            return obj;
        }
        if (obj instanceof Date) {
            return new Date(obj.getTime());
        }
        if (Array.isArray(obj)) {
            return obj.map(item => Utilities.deepCopy(item));
        }
        if (typeof obj === "object") {
            const copiedObject = Object.assign({}, obj);
            Object.keys(copiedObject).forEach(key => {
                copiedObject[key] = Utilities.deepCopy(obj[key]);
            });
            return copiedObject;
        }
        return obj;
    }

    static italicize(input: string | null | undefined): string {
        if(!input) {
            return '';
        }

        return `<span class="italics">${input}</span>`;
    }

    static bold(input: string | null | undefined): string {
        if(!input) {
            return '';
        }

        return `<span class="bold">${input}</span>`;
    }

    static validDateValidator() {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            if (value) {
                const date = new Date(value);
                if (isNaN(date.getTime())) {
                    // Invalid date
                    return { invalidDate: 'Invalid date' };
                } else {
                    // Check if the year is greater than 9999
                    const year = date.getFullYear();
                    if (year > 9999) {
                        return { invalidDate: 'Invalid date' };
                    }
                }
            }
            return null;
        };
    }
}