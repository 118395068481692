import { AutomationMonitor, ListComponent } from "reg-hub-common";

export abstract class AutomationMonitorComponent extends ListComponent<AutomationMonitor> {
    protected getIcon(monitor: AutomationMonitor): string {
        if (monitor.isFailed || !monitor.isAutomationEnabled) {
            return 'cancel';
        } else if (monitor.isWarning) {
            return 'warning'
        } else {
            return 'check_circle'
        }
    }

    protected getIconClass(monitor: AutomationMonitor): string {
        if (monitor.isFailed || !monitor.isAutomationEnabled) {
            return 'icon-error';
        } else if (monitor.isWarning) {
            return 'icon-warning'
        } else {
            return 'icon-success'
        }
    }
}