import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { Order } from '../../interfaces/orders/order';
import { OrderUIConfiguration } from '../../interfaces/ui-configurations/order-ui-configuration';
import { eOrderType } from '../../enums';

@Injectable({
    providedIn: 'root'
})
export class UIConfigurationService {
    protected _baseLink: string;

    constructor(
        private http: HttpClient,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        this._baseLink = `${config.rootUrl}/UIConfigurations`;
    }

    public getOrderUIConfiguration = (order: Order) => {
        return this.http.get<OrderUIConfiguration>(
            `${this._baseLink}/${order.country}/${order.jurisdiction}/${order.orderTypeID}`, this.getOptions(order));
    }

    private getOptions(order: Order): { headers: HttpHeaders, params: HttpParams } {
        var options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })} as any;

        if(order.orderTypeID == eOrderType.amendment && order.referenceRegistrationInformation?.originalQCFormTypeID) {
            options['params'] = { 'qcFormTypeID': order.referenceRegistrationInformation.originalQCFormTypeID };
        } else if(order.lien?.qcFormTypeID) {
            options['params'] = { 'qcFormTypeID': order.lien.qcFormTypeID };
        }

        if(order.orderStatusTypeID.toString().includes("Historical")) {
            options['params'] = { ...options['params'], 'historical': true };
        }

        if(order.referenceRegistrationInformation?.originalOrderTypeID) {
            options['params'] = { ...options['params'], 'originalOrderTypeID': order.referenceRegistrationInformation.originalOrderTypeID };
        }

        return options;
    }
}