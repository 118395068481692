import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { Order } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../environment-url/environment-url.service';
import { AdminAuthService } from '../../auth/admin-auth.service';
import { orderStatusTypeLookup } from 'reg-hub-common';

@Injectable({
  providedIn: 'root'
})
export class OrderHubService {
  private hubUrl = "";
  private hubConnection!: signalR.HubConnection;

  private manualOrder = 'ManualOrder';
  private failedOrder = 'FailedOrder';
  private handledOrder = 'FailedOrManualOrderHandled';

  // BehaviorSubjects to store orders
  private manualOrdersSource = new BehaviorSubject<Order[]>([]);
  manualOrders$ = this.manualOrdersSource.asObservable();

  private failedOrdersSource = new BehaviorSubject<Order[]>([]);
  failedOrders$ = this.failedOrdersSource.asObservable();

  constructor(environmentUrl: EnvironmentUrlService,
              adminAuthService: AdminAuthService
  ) {
    this.hubUrl = environmentUrl.urlAddress + '/orderHub';
    this.startConnection(adminAuthService);
    this.registerOnServerEvents();
  }

  public setInitialManualOrders(orders: Order[]) {
    this.manualOrdersSource.next(orders);
  }

  public setInitialFailedOrders(orders: Order[]) {
    this.failedOrdersSource.next(orders);
  }

  private startConnection(adminAuthService: AdminAuthService) {
    this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(this.hubUrl, {
      accessTokenFactory: () => {
        const token = adminAuthService.getAccessToken();
        if (token) {
          return '' + token;
        } else {
          return Promise.reject('No access token available');
        }
      }
    })
      .withAutomaticReconnect()
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('SignalR connection established.'))
      .catch((err) => console.error('Error establishing SignalR connection: ', err));
  }

  private registerOnServerEvents() {
    // Handle manual order added
    this.hubConnection.on(this.manualOrder, (order: Order) => {
      this.addOrUpdateManualOrder(order);
    });

    // Handle failed order updated
    this.hubConnection.on(this.failedOrder, (order: Order) => {
      this.addOrUpdateFailedOrder(order);
    });

    // Handle order removed
    this.hubConnection.on(this.handledOrder, (orderId: string) => {
      this.removeOrder(orderId);
    });
  }

  private addOrUpdateManualOrder(order: Order) {
    let orders = this.manualOrdersSource.value;
    const index = orders.findIndex((o) => o.id === order.id);

    order.orderStatusTypeID = orderStatusTypeLookup[Number(order.orderStatusTypeID)];

    if (index !== -1) {
      // Update existing order
      orders[index] = order;
    } else {
      // Add new order
      orders.unshift(order);
      // Keep only the top 10 orders
      if (orders.length > 10) {
        orders = orders.slice(0, 10);
      }
    }

    this.manualOrdersSource.next([...orders]);
  }

  private addOrUpdateFailedOrder(order: Order) {
    // + operator here turns the string into an int
    let orders = this.failedOrdersSource.value;
    const index = orders.findIndex((o) => o.id === order.id);

    order.orderStatusTypeID = orderStatusTypeLookup[Number(order.orderStatusTypeID)];

    if (index !== -1) {
      // Update existing order
      orders[index] = order;
    } else {
      // Add new order
      orders.unshift(order);
      // Keep only the top 10 orders
      if (orders.length > 10) {
        orders = orders.slice(0, 10);
      }
    }

    this.failedOrdersSource.next([...orders]);
  }

  private removeOrder(orderId: string) {
    // Remove from manual orders
    let manualOrders = this.manualOrdersSource.value.filter((o) => o.id !== orderId);
    this.manualOrdersSource.next([...manualOrders]);

    // Remove from failed orders
    let failedOrders = this.failedOrdersSource.value.filter((o) => o.id !== orderId);
    this.failedOrdersSource.next([...failedOrders]);
  }
}
