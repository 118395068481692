import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'reg-hub-common';
import { DashboardComponent } from './app/components/dashboard/dashboard.component';
import { LoggedOutComponent } from './app/components/logged-out/logged-out.component';
import { CallbackComponent } from './app/components/callback/callback.component';
import { AdminGuard } from './auth-guard';
import { ClientListComponent } from './app/components/client-list/client-list.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'callback', component: CallbackComponent },
  { path: 'dashboard', component: DashboardComponent, data: { breadcrumb: { label: "Dashboard", url: "dashboard" } }, canActivate: [AdminGuard] },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule), data: { breadcrumb: { label: "Orders", url: "orders" } }, canActivate: [AdminGuard]  },
  { path: 'batches', loadChildren: () => import('./batches/batches.module').then(m => m.BatchesModule), data: { breadcrumb: { label: "Batches", url: "batches"} }, canActivate: [AdminGuard]  },
  { path: 'corporations', loadChildren: () => import('./corporations/corporations.module').then(m => m.CorporationsModule), data: { breadcrumb: { label: "Corporations", url: "corporations" } }, canActivate: [AdminGuard]  },
  { path: 'pricing', loadChildren: () => import('./product-pricing/product-pricing.module').then(m => m.ProductPricingModule), data: { breadcrumb: { label: "Pricing", url: "pricing"} }, canActivate: [AdminGuard]  },
  { path: 'invoices', loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule), data: { breadcrumb: { label: "Invoicing", url: "invoices"} }, canActivate: [AdminGuard]  },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), data: { breadcrumb: { label: "Reports", url: "reports"} }, canActivate: [AdminGuard]  },
  { path: 'registry-credentials', loadChildren: () => import('./registry-credentials/registry-credentials.module').then(m => m.RegistryCredentialsModule), data: { breadcrumb: { label: "Registry Credentials", url: "registry-credentials" } }, canActivate: [AdminGuard]  },
  { path: 'logs', loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule), data: { breadcrumb: { label: "Logs", url: "logs"} }, canActivate: [AdminGuard]  },
  { path: 'system', loadChildren: () => import('./system-management/system-management.module').then(m => m.SystemManagementModule), data: { breadcrumb: { label: "System", url: "system"} }, canActivate: [AdminGuard]  },
  { path: 'clients', component: ClientListComponent },
  { path: 'signout-callback-oidc', component: LoggedOutComponent },
  { path: 'not-found', component: PageNotFoundComponent, data: { breadcrumb: { label: '404', url: "" } } },
  // Wildcard route for a 404 page
  { path: '**', component: PageNotFoundComponent, data: { breadcrumb: { label: '404', url: "" } } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }