import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientForCreation } from 'reg-hub-common';
import { ClientRepositoryService } from '../../../services/clients/client-repository.service';
import { Clipboard } from '@angular/cdk/clipboard'; // Import Clipboard service
import { ClientOnCreation } from 'reg-hub-common';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.css']
})
export class ClientFormComponent {

  clientForm: FormGroup;
  clientAdded: Boolean = false;
  clientSecret: string = "";
  clientName: string = "";

  constructor(
    private fb: FormBuilder,
    private clientService: ClientRepositoryService,
    private dialogRef: MatDialogRef<ClientFormComponent>,
    private clipboard: Clipboard
  ) {
    this.clientForm = this.fb.group({
      clientID: ['', Validators.required]
    });
  }

  onSubmit() {
    const newClient: ClientForCreation = this.clientForm.value;
    this.clientService.createClient(newClient).subscribe(
      (response: ClientOnCreation) => {
        this.clientSecret = response.clientSecret;
        this.clientName = this.clientForm.get('clientID')?.value;
        this.clientAdded = true;
      },
      (error) => {
        console.error('Error creating client:', error);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close('refresh');
  }

  onCancel() {
    this.dialogRef.close();
  }

  copyClientSecret() {
    this.clipboard.copy(this.clientSecret);
  }
}