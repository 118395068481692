import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentUrlService } from '../environment-url/environment-url.service';
import { Observable } from 'rxjs';
import { Client, ClientForCreation, ClientForUpdate, ClientOnCreation } from 'reg-hub-common';
import { PaginatedRepository } from 'reg-hub-common';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ClientRepositoryService extends PaginatedRepository<Client> {

  protected override _baseLink: string;

  constructor(
    http: HttpClient,
    private environmentUrl: EnvironmentUrlService,
    messageService: ToastrService) {
      super(http, messageService);
      this._baseLink = `${this.environmentUrl.urlAddress}/Clients`;
    }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  public createClient = (client: ClientForCreation) => {
    return this.http.post<ClientOnCreation>(
      `${this.environmentUrl.urlAddress}/clients`,
      client,
      this.generateHeaders());
  }

  public updateClient = (id: number, client: ClientForUpdate) => {
    return this.http.put(`${this.environmentUrl.urlAddress}/clients/${id}`, client, this.generateHeaders());
  }

  public deleteClient = (id: number) => {
    return this.http.delete(`${this.environmentUrl.urlAddress}/clients/${id}`);
  }
}
