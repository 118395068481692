import { Component } from '@angular/core';
import { AdminAuthService } from '../../../services/auth/admin-auth.service';
import { catchError, delay, forkJoin, of, takeUntil, takeWhile, tap } from 'rxjs';
import { DatabaseOverview, Order, OrderRepositoryService } from 'reg-hub-common';
import { DatabaseOverviewService } from '../../../services/database-overview/database-overview.service';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { QueueMonitorsRepositoryService } from '../../../services/queue-monitors/queue-monitors-repository.service';
import { ScheduledMonitorsRepositoryService } from '../../../services/scheduled-monitors/scheduled-monitors-repository.service';
import { OrderHubService } from '../../../services/websockets/order-hub/order-hub.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  protected _loading: boolean = false;

  protected _failedOrders: Order[] = [];
  protected _totalFailedOrders: number = 0;

  protected _manualOrders: Order[] = [];
  protected _totalManualOrders: number = 0;

  protected _databaseOverviews!: MatTableDataSource<DatabaseOverview, MatTableDataSourcePaginator>;

  constructor(
    private ordersRepo: OrderRepositoryService,
    private databaseOverviewRepo: DatabaseOverviewService,
    private queueMonitorRepo: QueueMonitorsRepositoryService,
    private authService: AdminAuthService,
    private scheduledMonitorRepo: ScheduledMonitorsRepositoryService,
    private orderHubService: OrderHubService) { }

  ngOnInit(): void {
    this._loading = true;

    const failedOrdersRequest = this.ordersRepo.getTopTenFailedOrders().pipe(catchError(() => of({ total: 0, resource: [] })));
    const manualOrdersRequest = this.ordersRepo.getTopTenManualOrders().pipe(catchError(() => of({ total: 0, resource: [] })));
    const databaseOverviewRequest = this.databaseOverviewRepo.getDatabaseOverviews().pipe(catchError(() => of([] as DatabaseOverview[])));

    //Take the requests for all 4 panels, combine them into one observable, and only set loading to false when all are complete
    forkJoin({
      failedOrders: failedOrdersRequest,
      manualOrders: manualOrdersRequest,
      databaseOverviews: databaseOverviewRequest,
      queueMonitors: this.queueMonitorRepo.getFirstPageSubscribable(),
      scheduledMonitorRepo: this.scheduledMonitorRepo.getFirstPageSubscribable()
    }).subscribe({ 
      next: responses => {
        this._databaseOverviews = new MatTableDataSource(responses.databaseOverviews);
        this.orderHubService.setInitialManualOrders(responses.manualOrders.resource);
        this.orderHubService.setInitialFailedOrders(responses.failedOrders.resource);

        this.subscribeToOrderUpdates();
      },
      complete: () => this._loading = false 
    });
  }

  private subscribeToOrderUpdates() {
    // Subscribe to manual orders updates
    this.orderHubService.manualOrders$.subscribe((orders) => {
      this._manualOrders = orders;
      this._totalManualOrders = orders.length;
    });

    // Subscribe to failed orders updates
    this.orderHubService.failedOrders$.subscribe((orders) => {
      this._failedOrders = orders;
      this._totalFailedOrders = orders.length;
    });
  }
}
