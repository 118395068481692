<form [formGroup]="countrySelectionForm">
    <div class="flex-container">
        <div class="validated-form-field-container flex-container">
            <mat-form-field [appearance]="appearance" class="form-field flex-item">
                <mat-label>{{ countryFieldName }}</mat-label>
                <mat-select [formControlName]="countryFieldName" placeholder="Country"
                    (selectionChange)="onCountryChange($event)">
                    <mat-option>
                        <ngx-mat-select-search
                            [formControl]="countryFilterControl"
                            placeholderLabel="Search..."
                            noEntriesFoundLabel=" No matches found">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let country of filteredCountries | async" [value]="country.value">
                        {{ country.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <lib-error-bubble [control]="countryFilterControl"
                [message]="countryError$"></lib-error-bubble>
        </div>
        <div class="validated-form-field-container flex-container">
            <mat-form-field [appearance]="appearance" class="form-field flex-item">
                <mat-label>{{ jurisdictionFieldName }}</mat-label>
                <mat-select [formControlName]="jurisdictionFieldName" placeholder="Jurisdiction"
                    (selectionChange)="onJurisdictionChange($event)" [multiple]="multiSelect">
                    <mat-option>
                        <ngx-mat-select-search
                            [formControl]="jurisdictionFilterControl"
                            placeholderLabel="Search..."
                            noEntriesFoundLabel=" No matches found">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let jurisdiction of filteredJurisdictions | async" [value]="jurisdiction.value">
                        {{ jurisdiction.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <lib-error-bubble [control]="jurisdictionFilterControl"
                [message]="jurisdictionError$"></lib-error-bubble>
        </div>
    </div>
</form>